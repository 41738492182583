
export default {
  name: 'Article',
  components: {},
  props: [
    'article-name',
    'article-price',
    'article-picture',
    'article-sku',
  ],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


