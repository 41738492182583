<template>
     <div class="row contenct-center">
        <img src="./assets/logo.png" alt="" srcset="">
    </div>
  <div class="container">

    <div class="row contenct-center">
        <div v-for="article in articles" :key="article" class="article col-sm-auto col-md-auto mt-3">
            <Article v-if="article[1]=='TRUE'" :article-sku=article[0] :article-name=article[2] :article-price=article[12] :article-picture=article[3] /> 
        </div>        
    </div>
  </div>  
</template>

<script>
import Article from './components/Article/Artilce-index.vue'

export default {
  name: 'App',
  components: {
    Article
  },
  mounted() {      
      fetch("https://sheets.googleapis.com/v4/spreadsheets/1Y5EdBATwsf5gGDyKZ43UVQTpUXUG9MfSBeJ_Rn_v-tw/values/precios?alt=json&key=AIzaSyAp0rgQHmlQ4zvoz__gM_cuz-7NNwSVrgU")
      .then(resp=>resp.json())
      .then(data=>{
          let a=data.values
          a.shift()
          this.articles=a          
      })
  },
  data() {
      return {
          articles: [1,2,3,4,5,6,7,8,9,11]
      }
  }
}
</script>

<style lang="scss">
body, html { overflow-x:hidden; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */  
}

body {
    background-color: #eee
}

.article {
    min-width: 100%
}

.container {
    
    @media screen and (min-width: 800) {
        width: 100%; 
    }
}

.card {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: auto;
}

.image-container {
    position: relative;
    padding: 5%;
}

.thumbnail-image {
    border-radius: 10px !important
}

.discount {
    background-color: red;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    border-radius: 6px;
    color: #fff
}

.wishlist {
    height: 25px;
    width: 25px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.first {
    position: absolute;
    width: 100%;
    padding: 9px
}

.dress-name {
    font-size: 13px;
    font-weight: bold;
    width: 75%
}

.new-price {
    font-size: 20px;
    font-weight: bold;
    color: red
}

.old-price {
    font-size: 8px;
    font-weight: bold;
    color: grey
}

.btn {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    padding: 3px
}

.creme {
    background-color: #fff;
    border: 2px solid grey
}

.creme:hover {
    border: 3px solid grey
}

.creme:focus {
    background-color: grey
}

.red {
    background-color: #fff;
    border: 2px solid red
}

.red:hover {
    border: 3px solid red
}

.red:focus {
    background-color: red
}

.blue {
    background-color: #fff;
    border: 2px solid #40C4FF
}

.blue:hover {
    border: 3px solid #40C4FF
}

.blue:focus {
    background-color: #40C4FF
}

.darkblue {
    background-color: #fff;
    border: 2px solid #01579B
}

.darkblue:hover {
    border: 3px solid #01579B
}

.darkblue:focus {
    background-color: #01579B
}

.yellow {
    background-color: #fff;
    border: 2px solid #FFCA28
}

.yellow:hover {
    border-radius: 3px solid #FFCA28
}

.yellow:focus {
    background-color: #FFCA28
}

.item-size {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid grey;
    color: grey;
    font-size: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center
}

.rating-star {
    font-size: 10px !important
}

.rating-number {
    font-size: 10px;
    color: grey
}

.buy {
    font-size: 12px;
    color: purple;
    font-weight: 500
}

.voutchers {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    width: 190px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden
}

.voutcher-divider {
    display: flex
}

.voutcher-left {
    width: 60%
}

.voutcher-name {
    color: grey;
    font-size: 9px;
    font-weight: 500
}

.voutcher-code {
    color: red;
    font-size: 11px;
    font-weight: bold
}

.voutcher-right {
    width: 40%;
    background-color: purple;
    color: #fff
}

.discount-percent {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 5px
}

.off {
    font-size: 14px;
    position: relative;
    bottom: 5px
}

@import'~bootstrap/dist/css/bootstrap.css'

</style>
