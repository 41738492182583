
            <div class="card">
              <div class="ribbon-wrapper">
                <div class="ribbon">OFERTA</div>
              </div>
              <div class="image-container"> 
                  <img :src=articlePicture class="img-fluid rounded thumbnail-image">
              </div>
              <div class="product-detail-container p-2">
                  <div class="d-flex justify-content-center align-items-center">
                      <h5 class="dress-name">{{articleName}}</h5>
                  </div>                  
                  <div class="d-flex justify-content-center align-items-center">
                    CODIGO: {{articleSku}}
                  </div>                  
                  <div class="d-flex justify-content-center align-items-center">                    
                    <span class="new-price">{{articlePrice}}</span>
                </div>                  
              </div>
              <a :href="'https://api.whatsapp.com/send?phone=528126696618&text=Me%20interesa%20el%20articulo:%20'+articleSku">
              <div class="wq-action-btn-group">                
                  <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2679 9.50836C11.0995 9.42182 10.2604 9.01076 10.1043 8.95513C9.94821 8.89641 9.83386 8.86859 9.72105 9.04167C9.6067 9.2132 9.28218 9.5949 9.18018 9.7108C9.08128 9.82515 8.98084 9.83906 8.8124 9.75407C7.81102 9.25338 7.15426 8.86087 6.4944 7.72814C6.31978 7.4268 6.66902 7.44844 6.99509 6.79785C7.05072 6.6835 7.0229 6.58614 6.97963 6.4996C6.93637 6.41307 6.59639 5.5755 6.45422 5.23398C6.31669 4.90173 6.17452 4.94809 6.07098 4.94191C5.97208 4.93573 5.85927 4.93573 5.74492 4.93573C5.63056 4.93573 5.44667 4.979 5.29059 5.14744C5.13451 5.31897 4.69409 5.73157 4.69409 6.56914C4.69409 7.40671 5.3045 8.21801 5.38794 8.33236C5.47448 8.44672 6.58867 10.1651 8.29935 10.9053C9.38108 11.372 9.8045 11.4122 10.3454 11.3319C10.6745 11.2824 11.3529 10.9208 11.4935 10.5206C11.6342 10.1219 11.6342 9.78034 11.5924 9.70925C11.5507 9.63353 11.4364 9.59026 11.2679 9.50836Z" fill="#23AB36"></path>
                    <path d="M14.5392 5.47116C14.1899 4.64132 13.6892 3.89647 13.051 3.2567C12.4173 2.62064 11.6649 2.11504 10.8366 1.76855C9.98664 1.41158 9.08417 1.23077 8.15388 1.23077H8.12297C7.1865 1.23541 6.27939 1.42085 5.42637 1.78555C4.60512 2.1356 3.85981 2.64209 3.232 3.27679C2.59996 3.91501 2.10391 4.65677 1.76085 5.48352C1.40542 6.33963 1.22616 7.24983 1.2308 8.1863C1.23604 9.25949 1.48994 10.3169 1.97256 11.2754V13.6243C1.97256 13.8128 2.04745 13.9937 2.18076 14.127C2.31407 14.2603 2.49488 14.3352 2.68341 14.3352H5.03386C5.99242 14.8178 7.04978 15.0717 8.12297 15.0769H8.15542C9.08108 15.0769 9.97891 14.8977 10.8242 14.5469C11.6484 14.2045 12.3979 13.7048 13.0309 13.0757C13.6692 12.4437 14.1714 11.705 14.5222 10.8814C14.8869 10.0283 15.0723 9.12123 15.077 8.18476C15.0816 7.24365 14.8992 6.33036 14.5392 5.47116ZM12.2042 12.2397C11.1209 13.3122 9.68375 13.9025 8.15388 13.9025H8.12761C7.19577 13.8978 6.27012 13.666 5.45264 13.2303L5.32283 13.1607H3.14701V10.9849L3.07747 10.8551C2.64169 10.0376 2.40989 9.11195 2.40525 8.18012C2.39907 6.63943 2.98784 5.193 4.06803 4.10354C5.14667 3.01408 6.58846 2.41141 8.12915 2.40522H8.15542C8.92809 2.40522 9.67757 2.55512 10.3838 2.85182C11.073 3.1408 11.6911 3.55649 12.2227 4.08809C12.7528 4.61814 13.17 5.23781 13.459 5.92703C13.7588 6.64097 13.9087 7.39818 13.9056 8.18012C13.8963 9.71927 13.2921 11.1611 12.2042 12.2397Z" fill="#23AB36"></path>
                  </svg>                  
                  <span>Quiero Información</span>
              </div>
              </a>
          </div>

